export const ZoomShape = {
  hover: {
    scale: 1.03,
    transition: {
      duration: 0.1,
    },
  },
  initial: {
    scale: 1,
    transition: {
      duration: 0.1,
    },
  },
};

export const ZoomTextImage = {
  hover: {
    scale: 1.02,
    transition: {
      duration: 0.1,
    },
  },
  initial: {
    scale: 1,
    transition: {
      duration: 0.1,
    },
  },
};

export const ZoomIn = {
  hover: {
    scale: 1.01,
    transition: {
      duration: 0.1,
    },
  },
  initial: {
    scale: 1,
    transition: {
      duration: 0.1,
    },
  },
};

export const animDesc = {
  variants: ZoomTextImage,
  style: { cursor: "pointer" },
};
export const animHeader = {
  variants: ZoomTextImage,
};
export const animImg = {
  variants: ZoomTextImage,
  style: { cursor: "pointer" },
};
export const animShape = {
  variants: ZoomShape,
};
