import React, { useEffect, useState } from "react";
import { Turn as Hamburger } from "hamburger-react";

import { motion } from "framer-motion";
import { Link } from "gatsby";
import logo from "../images/logotype.svg";
import "../styles/navbar.scss";
import "../styles/typography.scss";

export const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    function handleResize() {
      let isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (!isPortrait || window.innerWidth > 768) {
        // const menuElement = document.getElementsByClassName("navbar__wrapper__links");
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    }
    handleResize();
    document.body.style.position = "inherit";
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div className="navbar">
      <nav className="navbar__wrapper">
        <div className="navbar__wrapper__logo">
          <a href="/">
            <motion.img
              src={logo}
              whilehover={{
                x: 10,
              }}
            />
          </a>
        </div>
        <div className="show-burger">
          <Hamburger
            toggled={showMenu}
            toggle={setShowMenu}
            onToggle={(toggled) => {
              if (window.innerWidth < 768) {
                if (!toggled) {
                  document.body.style.position = "inherit";
                } else {
                  document.body.style.position = "fixed";
                }
              }
            }}
          />
        </div>
        {showMenu && (
          <div className="navbar__wrapper__links">
            <ul className="navbar__wrapper__links-wrapper ">
              {[
                {
                  label: "We are motion",
                  to: "/wearemotion",
                  defaultPath: true,
                },
                { label: "We are still", to: "/wearestill" },
                { label: "We are g58", to: "/weareg58" },
                { label: null, to: "/" },
              ].map(({ label, to, defaultPath }, index) => {
                function isActive(link) {
                  if (window.location.pathname.startsWith(link)) {
                    return true;
                  } else if (
                    defaultPath &&
                    (window.location.pathname == "" ||
                      window.location.pathname == "/")
                  ) {
                    return true;
                  }
                  return false;
                }
                if (label) {
                  return (
                    <li
                      key={index}
                      className={`navbar__wrapper__links-single ${
                        isActive(to) ? "active" : ""
                      }`}
                    >
                      <Link to={to} onClick={() => {}}>
                        {label}
                      </Link>
                    </li>
                  );
                } else {
                }
              })}
            </ul>
          </div>
        )}
      </nav>
      {/* <div className="nav__fix-height" /> */}
    </div>
  );
};
export default Navbar;
