import React from "react";
import logoImage from "../images/g58-footer-logo.svg";
import logoText from "../images/g58-footer-text.svg";
import "../styles/footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__responsive">
        <div className="footer__wrapper">
          <div className="footer__left">
            <img src={logoText} className="footer__left__image--logo" />
            <img src={logoImage} className="footer__left__image--text" />
          </div>
          <div className="footer__contact">
            <div className="footer__contact__center">
              <p className="footer__contact__center-data">G58 Sp. z. o. o.</p>
              <p className="footer__contact__center-contact">
                <a href="mailto:weare@g58.pl">weare@g58.pl</a>
              </p>
              <p className="footer__contact__center-phone">
                <a href="tel:+48604339933">+48 604 33 99 33</a>
              </p>
            </div>
            <div className="footer__contact__right">
              <a
                href="https://www.facebook.com/studiog58"
                target="_blank"
                rel="noreferrer"
              >
                <div className="footer__contact__right-fb"></div>
              </a>
              <a
                href="https://www.instagram.com/garage_58/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="footer__contact__right-in"></div>
              </a>
              <a
                href="https://www.behance.net/ad-gr-020516"
                target="_blank"
                rel="noreferrer"
              >
                <div className="footer__contact__right-bh"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
