import React from "react";

const Container = ({ children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        maxWidth: "1920px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {children}
    </div>
  );
};
export default Container;
