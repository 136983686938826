import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import LetsTalk from "./letsTalk";
import "../styles/typography.scss";
const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      {/* <LetsTalk>
        <button className="button__contactUs_fixed">Let's Talk</button>
      </LetsTalk> */}
      <div style={{ marginTop: "50px" }}>{children}</div>
      <Footer />
    </>
  );
};
export default Layout;
